import React  from "react";
import "./App.css";
import ProfileComponent from "./components/profile";
import ComplainsComponent from "./components/complains";
import { Route, Routes, useLocation } from "react-router-dom";
  

function App() {
  const location = useLocation();
  return (

    <Routes location={location} key={location.pathname}>
          <Route path="/profile/:id/:chapterId" element={<ProfileComponent />} />
          <Route path="/complains" element={<ComplainsComponent />} />
    </Routes>
    
  );
}

export default App;
