import React from "react";
import "../App.css";
import logo from "../images/Logo-Net-Session-Manager.png";
import logoBack from "../images/reunion.png";
import logoName from "../images/nestSessionManagerName.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";

const header = () => {
  return (
    <Container>
      <Row>
        <Col style={{ textAlign: "left" }}>
          <img
            src={logo}
            style={{
              width: "4rem",
              marginTop: "17px",
              marginBottom: "10px",
            }}
            alt=""
          ></img>
        </Col>
        <Col  style={{ textAlign: "right" }}>
          <img
            src={logoName}
            style={{
              marginTop: "25px",
            }}
            alt=""
          ></img>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="12" style={{ textAlign: "center" }}>
          <img
            src={logoBack}
            style={{
              width: "26.9rem",
              backgroundColor: "black",
              height: "194px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              marginLeft: "-12px",
              filter: "brightness(60%)",
            }}
            alt=""
          ></img>
        </Col>
      </Row>
    </Container>
  );
};

export default header;
