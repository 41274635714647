import React from 'react'

const complains = () => {
  return (
    <div>
      hello there
    </div>
  )
}

export default complains
