import "../App.css";
import React, { useState, useEffect } from "react";
import HeaderComponent from "../components/header";
import { useParams } from "react-router-dom";
import QRCode from "qrcode";
import placeHolderImage from "../images/placeholder.jpg";
// import { Helmet } from "react-helmet";
import Col from "react-bootstrap/Col";

const Profile = () => {
  const params = useParams(0);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [profession, setProfession] = useState("");
  const [imageProfile, setImagenProfile] = useState("");
  const [qrcodeImage, setQrCodeImage] = useState(null);
  const [chapterName, setChapterName] = useState(""); 

  useEffect(() => {
    fetch(
      `https://miregistrobackend.com/users/getInformation/${params.id}/${params.chapterId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          setName(data.result.name);
          setCompany(data.result.companyName);
          setProfession(data.result.profession);
          setChapterName(data.result.chapterName);
          setImagenProfile(
            data.result.image !== "" ? data.result.image : placeHolderImage
          );
          generateQR(params.id);
        } else {
          alert("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const generateQR = (id) => {
    try {
      QRCode.toDataURL(id, { version: 2 }, function (err, url) {
        setQrCodeImage(url);
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      {/* <Helmet> */}
        <meta charSet="utf-8" />
        <title>Net Session Manager</title>
      {/* </Helmet> */}

      <HeaderComponent></HeaderComponent>
      <Col xs="12" md="12" lg="12" >
        <div style={{ textAlign: "center", backgroundColor: "#f1f3f4" }}>
          <img src={imageProfile} className="cssPhoto" alt=""></img>
          <h1 className="cssName">{name}</h1> <br />
          <p className="cssCompany">{company}</p>
          <p className="cssCompany">{profession}</p>
          <p className="cssMessage">
            Registra tu asistencia con este código QR{" "}
          </p>
          <img
            src={qrcodeImage}
            style={{
              width: "17rem",
              boxShadow:"0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
            alt=""
          ></img>
          <br />
          <br />
          <p className="cssChapterFoot">{chapterName}</p>
          <br /><br />
        </div>
      </Col>
    </div>
  );
};

export default Profile;
